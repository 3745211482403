import { Component, OnInit, Inject, Input } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { swaggerApiService } from "../services/swaggerApi.service";
import { ApiModel } from "../classes/api_model";
import { JSGenerateRSAKey } from "../services/JSGenerateRSAKey";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpResponse } from '@angular/common/http';
import {MatTabsModule} from '@angular/material/tabs';


export interface DetailData {
  title: string;
  english: string;
  french: string;
  thumb: string;
  khmer: string;
  imgs: [];
  date: string;
}

@Component({
  selector: "app-tools",
  templateUrl: "./tools.component.html",
  styleUrls: ["./tools.component.css"]
})
export class ToolsComponent implements OnInit {
  toolList = [];
  newsList = [];
  test = [];
  key: JSGenerateRSAKey;
  isLoading = false;


  @Input("lang") currentLanguage: string = "";

  constructor(
    private db: AngularFirestore,
    public dialog: MatDialog,
    private router: Router,
    private _swaggerApiService: swaggerApiService,
    private _snackBar: MatSnackBar
  ) {
   
  }

  ngOnInit() {
   
    this.key = new JSGenerateRSAKey();
    let objectLibrary = new ApiModel();
    objectLibrary.public_key = this.key.getPublicKey();
    objectLibrary.modified_date = new Date().toISOString();
    objectLibrary.limit = 100;

   
    this._swaggerApiService.postLibraryGetLists(objectLibrary)
      .subscribe
      (
        data => {
          let objDecrypt = new ApiModel();
          objDecrypt.private_key = this.key.getPrivateKey();
          objDecrypt.encryptedData = data;

          this.isLoading = true;
          this._swaggerApiService.postDecrypted(objDecrypt)
            .subscribe
            (
              data => {
                if (data.statusCode === 200 && data.item.length > 0) {
                  let theLib = data.item;
                  // Example keywords to exclude
                  const excludeKeywords = [
                    "សទ្ទានុក្រមស្តីពីភាពជាដៃគូរវាងរដ្ឋ និងឯកជន", 
                    "សទ្ទានុក្រមស្ដីពីការកែទម្រង់ប្រព័ន្ធថវិកា", 
                    "Volume I: Policies and Procedures",
                    "Volume II: Guidelines",
                    "Volume III: Procurement Manual",
                    "Volume III: Procurement Manual Annexures",
                    "ច្បាប់ស្តីពីភាពជាដៃគូរវាងរដ្ឋ  និងឯកជន",
                    "Law On  Public-Private Partnerships"
                  ];
                  // Filtering data to exclude items with any of the keywords
                  theLib.forEach(element => {
                    element.item = element.item.filter((subItem) =>
                      !excludeKeywords.some(keyword => subItem.titleKh.includes(keyword))
                    );
                    if (element.item.length > 0) {
                      this.toolList.push(element);
                    }
                  });
                  this.isLoading = false;
                  // console.log("Filtered toolList == ", this.toolList);
                } 
                // if (data.statusCode == 200 && data.item.length > 0) {
                //   let theLib = data.item;
                //   theLib.forEach(element => {
                //     this.toolList.push(element);
                //     // setInterval(() => this.isLoading = false, 4000);
                //     // console.log('####',this.isLoading );
                //   });
                //   this.isLoading = false;
                //   console.log("toolList  == ", this.toolList);
                // }
                
                else if (data.statusCode == 200 && data.item.length <= 0) {
                  //Toast message to users
                  this._snackBar.open("oops! no records found.", "OK", {
                    duration: 2000
                  });
                }
                else {
                  //Toast message to users
                  this._snackBar.open(data.message, "OK", {
                    duration: 2000
                  });
                }
              }, (error: HttpResponse<Response>) => {
                let errorMsg = error['message'];
                this._snackBar.open("LibraryLists Decrypted Res_Err: " + errorMsg, "OK", {
                  duration: 2000
                });
              }
            )
        }, (error: HttpResponse<Response>) => {
          let errorMsg = error['message'];
          this._snackBar.open("LibraryLists Res_Err: " + errorMsg, "OK", {
            duration: 2000
          });
        }
      )
  }

  visionClick() {
    console.log("vision");
  }

  onAboutlicked(section: string) {
    console.log("in tool : " + section);
    this.router.navigate(["/", section]);
  }

  openDialog(news) {

    // console.log("the title", news);
    let str: String = news.images;
    let imgList = str.split(",");
    // console.log("the log : ", imgList);
    const dialogRef = this.dialog.open(ToolDetail, {
      data: {
        title: news.title,
        english: news.english,
        french: news.french,
        thumb: news.thumb,
        imgs: imgList,
        khmer: news.khmer
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log("The dialog was closed");
    });
  }
  navigateDetailNewTab(news) {
    // window.open('read-detail', '_blank');
    // this.router.navigate(['read-detail'], { queryParams: { data:JSON.stringify(news),newsList:JSON.stringify(this.newsList)} });
    // this.router.navigate(['read-detail',news.newsId]);
    window.open('details/compendium/' + news._id + '/YES' + '/' + this.currentLanguage, '_blank');
  }
  findLang(tool:any) {
    
    if (this.currentLanguage == 'km' && (tool.title != null && tool.title != undefined && tool.title != '')) {
      return tool.title;
    } else if (this.currentLanguage == 'en' && (tool.titleEn != null && tool.titleEn != undefined && tool.titleEn != '')) {
      return tool.titleEn;

    } else if (this.currentLanguage == 'fr' && (tool.titleFr != null && tool.titleFr != undefined && tool.titleFr != '')) {
      return tool.titleFr;

    }
    return tool.title;
  }
  toKhmerNumber(str) {
    var khNumber = str.toString();

    khNumber = khNumber.replace(/0/g, '០')
    khNumber = khNumber.replace(/1/g, '១')
    khNumber = khNumber.replace(/2/g, '២')
    khNumber = khNumber.replace(/3/g, "៣")
    khNumber = khNumber.replace(/4/g, "៤")
    khNumber = khNumber.replace(/5/g, "៥")
    khNumber = khNumber.replace(/6/g, "៦")
    khNumber = khNumber.replace(/7/g, "៧")
    khNumber = khNumber.replace(/8/g, "៨")
    khNumber = khNumber.replace(/9/g, "៩")

    return khNumber
  }
  convertYear(year) {
    let yearStr = year;
    if (this.currentLanguage == 'km') {
      yearStr = this.toKhmerNumber(year);
    }
    return yearStr;
  }
}

@Component({
  selector: "tool-detail",
  templateUrl: "tool-detail.html"
})

export class ToolDetail {
  title: string;

  constructor(
    public dialogRef: MatDialogRef<ToolDetail>,
    @Inject(MAT_DIALOG_DATA) public data: DetailData
  ) {
    console.log("this is title : ", this.title);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
