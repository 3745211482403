import { Component, Inject, OnInit,Input,ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from "./../translate.service";
import {swaggerApiService} from '../services/swaggerApi.service';
import { ApiModel } from '../classes/api_model';
import { Posts } from '../classes/posts';
import {JSGenerateRSAKey} from '../services/JSGenerateRSAKey';
import {MatSnackBar} from '@angular/material/snack-bar';
import { HttpResponse } from '@angular/common/http';
import { element } from 'protractor';
import { log } from 'console';
import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import {MatTabsModule} from '@angular/material/tabs';
import { MatPaginator } from '@angular/material/paginator';


export interface DetailData {
  title: string;
  english: string;
  french: string;
  thumb: string;
  khmer: string;
  imgs: [];
  date: string;
}

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit {
  newsList = [];
  preNewsList = [];//To store news array list for previous
  isLoading = false;
  @Input() currentLanguage: string;
  key:JSGenerateRSAKey;
  pageNum='';
  totalRec=0;
  disBtnPre=true;
  disabledNext=false;
  @ViewChild("ref1",{ static: false }) ref1;

  // FB
  newsListFB = [];
  paging:any=[];
  preNewsListFB:any=[];
  // End FB

  listDisplay:any = [];
  //loading page
 
  constructor(private db: AngularFirestore, public dialog: MatDialog, private router: Router,private translate: TranslateService,private _swaggerApiService:swaggerApiService,private _snackBar: MatSnackBar) {
    // this.db
    //   .collection('article', ref => ref.orderBy('create', 'desc').limit(5))
    //   .get()
    //   .subscribe(queyrSnap => {
    //     queyrSnap.forEach(doc => {
    //       let docId = doc.id;
    //       let theDoc = doc.data();
    //       //
    //       //firestore.Timestamp.fromDate(jsDate
    //       let theDate = theDoc['create'];
    //       console.log('you will done it : ' + theDate.seconds);
    //       //
    //       let str: String = theDoc.images;
    //       let imgs = str.split(',');
    //       theDoc.imgs = imgs;
    //       theDoc.newsId = docId;
    //       this.newsList.push(theDoc);
    //     });
    //   });
  }

  onNext() {
    // this.isLoading = true;
    let index = this.newsList.length - 1;
    let lastList = this.newsList[index];

    this.db
      .collection('article', ref =>
        ref.orderBy('create', 'desc')
          .limit(5)
          .where('create', '<', lastList['create']),
      )
      .get()
      .subscribe(queyrSnap => {
        this.newsList = [];
        queyrSnap.forEach(doc => {
          //
          let docId = doc.id;
          //
          let theDoc = doc.data();
          //
          //firestore.Timestamp.fromDate(jsDate
          let theDate = theDoc['create'];
          console.log('you will done it : ' + theDate.seconds);
          //
          let str: String = theDoc.images;
          let imgs = str.split(',');
          theDoc.imgs = imgs;
          theDoc.newsId = docId;
          this.newsList.push(theDoc);
          // this.isLoading = false;
          window.scroll(0, 0);
        });
        // console.log("=======> ", this.newsList);
      }
    );
  }
onNewsNextFB(ref)
{
  if(ref._disabled==true)
  {
    ref._disabled= true;
    ref._checked=true;
    ref._disableRipple=true;
    ref._appearance="legacy";
    return;
  }
  // this.isLoading = true;
  this._swaggerApiService.getNewsFB(this.paging.next)
  .subscribe
  (
    response =>
    {
      let url=this.paging.next;
      if(response.data.length>0)
      {
        this.newsListFB = [];
        response.data.forEach(element => 
        {
          // console.info("element.message =",element.message);
          if(element.message!=undefined)
          { 
            // element.message="កម្រងច្បាប់ខ្មែរពីបុរាណ ភាគ៣ និងភាគ៤\n----------\nជាសក្ខីកម្មប្រវត្តិសាស្ត្រជាតិបញ្ជាក់អំពីអត្ថិភាពនៃវប្បធម៌នីតិសាស្ត្រ ធម្មសាស្ត្រ និងយុត្តិធម៌របស់ខ្មែរ ដែលមានតាំងពីយូរលង់ណាស់មកហើយ គឺមុនពេលមកដល់នៃឥទ្ធិពលប្រព័ន្ធច្បាប់និងប្រព័ន្ធយុត្តិធម៌បារាំងទៅទៀត ដែលបន្តពីកម្រងច្បាប់ខ្មែរពីបុរាណ ភាគ១ និងភាគ២ ពោលគឺជាកម្រងច្បាប់ខ្មែរពីបុរាណឆ្នាំ១៨៩១។ \n|\nComplication of Khmer Ancient Law, Volume III and IV\n----------\nHistorical attestation of the Khmer legal, Dharma and judicial culture from the ancient time before the influence of the French culture and system. This Compilation is the sequel of Compilation of Khmer Ancient Law Part I and II, which are the compilation of laws in 1891. \n|\nCodes anciens du Cambodge (Volume 3 et Volume 4)\n----------\nLe Recueil des anciens codes khmers (2020) est un témoignage historique qui justifie l’existence de la culture juridique (de Nitisastra), de Dhammasastra et judiciaire (de la justice) khmère, depuis les temps anciens, avant que les systèmes juridique et judiciaire khmers ne subissent l’influence du système français. Ce Recueil a été produit à la suite de celui des anciens codes khmers de deux volumes (1891).";
            let newsEl=element;
            let sms=element.message.split("\n|\n");
            if(sms[0].indexOf("\n----------\n")!=-1)
            {
              newsEl.titleKh=sms[0].split("\n----------\n")[0];
              newsEl.khDes=sms[0].split("\n----------\n")[1];
              
              if(sms[1]!=undefined)
              {
              newsEl.titleEn=sms[1].split("\n----------\n")[0];
              newsEl.enDes=sms[1].split("\n----------\n")[1];
              }
              if(sms[2]!=undefined)
              {
              newsEl.titleFr=sms[2].split("\n----------\n")[0];
              newsEl.frDes=sms[2].split("\n----------\n")[1];
              }
            }
            else
            {
              newsEl.titleKh='';
              newsEl.titleEn='';
              newsEl.titleFr='';

              newsEl.khDes=element.message;
              newsEl.enDes=element.message;
              newsEl.frDes=element.message;
            }
            newsEl.url=url;
            this.newsListFB.push(newsEl);
          }
        });
        // this.isLoading = false;
        window.scroll(0, 0);
        this.paging=response.paging;
        let newsPreEl:any=this.newsListFB;
        newsPreEl.paging=this.paging;
        this.preNewsListFB.push(newsPreEl);
        // console.info("paging = ",this.paging.previous);
      }
      else
      {
        this._snackBar.open("oops! no more records found.", "OK", {
          duration: 2000
        });
        window.scroll(0, 0);
      }
      // console.info("response = ",response);

      // this.disabledMatBtnFB();
    },(error:HttpResponse<Response>) => 
      {
        //get error response from FB
        let errorMsg=error['error'].error.message;
        this._snackBar.open(errorMsg, "OK", {
          duration: 2000
        });
      }
  );
}
//To call news/get_list swagger API
  onNewsNext(ref)
  {
    if(ref._disabled==true)
    {
      ref._disabled= true;
      ref._checked=true;
      ref._disableRipple=true;
      ref._appearance="legacy";
      return;
    }
    // this.isLoading = true;
    let index = this.newsList.length - 1;
    let lastList = this.newsList[index];

    this.key=new JSGenerateRSAKey();
    var objBody=new ApiModel();
    objBody.public_key=this.key.getPublicKey();
    objBody.modified_date=lastList.created;
    objBody.limit=5;

    this._swaggerApiService.postNewsGetLists(objBody)
    .subscribe
    (
      data=>
      {
        var objDecrypt=new ApiModel();
        objDecrypt.encryptedData=data;
        objDecrypt.private_key=this.key.getPrivateKey();

        this._swaggerApiService.postDecrypted(objDecrypt)
        .subscribe
        (
          data=>
          {          
            if(data.statusCode == 200 && data.item.item.length>0)
            {
              //mean it is success
              let theNews=data.item.item;
              theNews.loadmore=data.item.item.length;
              data.item.item.forEach(element => 
              {
                this.newsList.push(element);           
                // this.isLoading = false;
                window.scroll(0, 0);
              });
             
              this.preNewsList.push(theNews);
              this.pageNum=this.preNewsList.length+"/"+this.totalRec;
              // this.disabledMatBtn();
            }
         
            else if(data.statusCode==200 && data.item.item.length<=0)
            {
              window.scroll(0, 0);
              //Toast message to users
              this._snackBar.open("oops! no more records found.", "OK", {
                duration: 2000
              });
            }
            else
            {
              //Toast message to users
              this._snackBar.open(data.message, "OK", {
                duration: 2000
              });
              
              window.scroll(0, 0);
            }

          },(error:HttpResponse<Response>) => {
            let errorMsg=error['message'];
            this._snackBar.open("News-Next Decrypt Res_Err: "+errorMsg, "OK", {
              duration: 2000
            });
            
          }
        )
      },(error:HttpResponse<Response>) => {
        let errorMsg=error['message'];
        this._snackBar.open("News-Next Res_Err: "+errorMsg, "OK", {
          duration: 2000
        });
        
      }
    )
  }
//end onNewsNext
  onPrevious() {

    // this.isLoading = true;
    let firstList = this.newsList[0];

    this.db
      .collection('article', ref =>
        ref
          .orderBy('create', 'desc')
          .limit(5)
          .where('create', '<', firstList['create']),
      )
      .get()
      .subscribe(queyrSnap => {
        this.newsList = [];
        queyrSnap.forEach(doc => {
          //
          let docId = doc.id;
          //
          let theDoc = doc.data();
          //
          //firestore.Timestamp.fromDate(jsDate
          let theDate = theDoc['create'];
          console.log('you will done it : ' + theDate.seconds);
          //
          let str: String = theDoc.images;
          let imgs = str.split(',');
          theDoc.imgs = imgs;
          theDoc.newsId = docId;
          this.newsList.push(theDoc);
          // this.isLoading = false;
          window.scroll(0, 0);
        });
      });
  }

  onNewsPreviousFB(ref)
  {
    if(ref._disabled==true)
    {
      ref._disabled= true;
      ref._checked=true;
      ref._disableRipple=true;
      ref._appearance="legacy";
      return;
    }
    if(this.preNewsListFB.length==1)
    return;
  
  this.newsListFB=[]
  this.preNewsListFB.pop();
  let newsIndex=this.preNewsListFB.length-1;
  this.newsListFB=this.preNewsListFB[newsIndex];
  this.paging=this.preNewsListFB[newsIndex].paging;
  window.scroll(0, 0);
  // this.disabledMatBtnFB();
  }
  //To use with swagger API
  onNewsPrevious(ref)
  {
    if(ref._disabled==true)
    {
      ref._disabled= true;
      ref._checked=true;
      ref._disableRipple=true;
      ref._appearance="legacy";
      return;
    }
    if(this.preNewsList.length==1)
      return;
    
    this.newsList=[]
    this.preNewsList.pop();
    let newsIndex=this.preNewsList.length-1;
    this.newsList=this.preNewsList[newsIndex];
    this.pageNum=this.preNewsList.length+"/"+this.totalRec;
    // this.disabledMatBtn();
  }

  //end onNewsPrevious
  public navigateToDetail(news) {
    let url=news.url.replace("https://graph.facebook.com/",'');
    url=url.split("/");
    let parameter=url[2].split("?");
    parameter[1]=parameter[1].replace(/=/g,'@@');
    let fb_url=url[0]+'/'+url[1]+'/'+parameter[0]+'/'+parameter[1];
    window.open('details/article/'+news.id+'/YES'+'/'+fb_url+'/'+this.currentLanguage,'_blank' );
  }

  
//  @ViewChild("refBtnPre", { static: false }) refBtnPre;
//  @ViewChild("refBtnNext", { static: false }) refBtnNext;
 
//  disabledMatBtnFB()
//   {
//     let isChecked=false;
//     let isSetColor=false;
//     if(this.paging.next==undefined)
//     {
//       this.disabledNext=true;
//       this.refBtnNext._disabled= true;
//       this.refBtnNext._checked=true;
//       this.refBtnNext._disableRipple=true;
//       this.refBtnNext._appearance="legacy";
//       //To remove checked and green color when button-toggle disabled(refBtnNext)
//       isChecked=(this.refBtnNext._elementRef.nativeElement.classList).contains("mat-button-toggle-checked");
//       isSetColor=(this.refBtnNext._elementRef.nativeElement.classList).contains("setColor");
//       if(isChecked)
//       {
//         this.refBtnNext._elementRef.nativeElement.classList.remove("mat-button-toggle-checked")
//       }
//       if(isSetColor)
//       {
//         this.refBtnNext._elementRef.nativeElement.classList.remove("setColor")
//       }
//     }
//     else if(this.preNewsListFB.length==1)
//     {
//       this.disBtnPre=true;
//       this.refBtnPre._disabled= true;
//       this.refBtnPre._checked=true;
//       this.refBtnPre._disableRipple=true;
//       this.refBtnPre._appearance="legacy";
//       //To remove checked and green color when button-toggle disabled(refBtnPre)
//       isChecked=(this.refBtnPre._elementRef.nativeElement.classList).contains("mat-button-toggle-checked");
//       isSetColor=(this.refBtnPre._elementRef.nativeElement.classList).contains("setColor");
//       if(isChecked)
//       {
//         this.refBtnPre._elementRef.nativeElement.classList.remove("mat-button-toggle-checked")
//       }
//       if(isSetColor)
//       {
//         this.refBtnPre._elementRef.nativeElement.classList.remove("setColor")
//       }
//     }
//     else
//     {
//       this.disBtnPre=false;
//       this.refBtnPre._disabled= false;
//       this.refBtnPre._checked=false;
//       this.refBtnPre._disableRipple=false;
//       this.refBtnPre._appearance="standard";
//       this.refBtnPre._elementRef.nativeElement.classList.add("setColor");

//       this.disabledNext=false;
//       this.refBtnNext._disabled= false;
//       this.refBtnNext._checked=false;
//       this.refBtnNext._disableRipple=false;
//       this.refBtnNext._appearance="standard";
//       this.refBtnNext._elementRef.nativeElement.classList.add("setColor");
      
//     }
//   }

  // //To call with swagger API
  // disabledMatBtn()
  // {
  //   let isChecked=false;
  //   let isSetColor=false;
  //   if(this.preNewsList.length*5>=this.totalRec-5)
  //   {
  //     this.disabledNext=true;
  //     this.refBtnNext._disabled= true;
  //     this.refBtnNext._checked=true;
  //     this.refBtnNext._disableRipple=true;
  //     this.refBtnNext._appearance="legacy";
  //     //To remove checked and green color when button-toggle disabled(refBtnNext)
  //     isChecked=(this.refBtnNext._elementRef.nativeElement.classList).contains("mat-button-toggle-checked");
  //     isSetColor=(this.refBtnNext._elementRef.nativeElement.classList).contains("setColor");
  //     if(isChecked)
  //     {
  //       this.refBtnNext._elementRef.nativeElement.classList.remove("mat-button-toggle-checked")
  //     }
  //     if(isSetColor)
  //     {
  //       this.refBtnNext._elementRef.nativeElement.classList.remove("setColor")
  //     }
  //   }
  //   else if(this.preNewsList.length==1)
  //   {
  //     this.disBtnPre=true;
  //     this.refBtnPre._disabled= true;
  //     this.refBtnPre._checked=true;
  //     this.refBtnPre._disableRipple=true;
  //     this.refBtnPre._appearance="legacy";
  //     //To remove checked and green color when button-toggle disabled(refBtnPre)
  //     isChecked=(this.refBtnPre._elementRef.nativeElement.classList).contains("mat-button-toggle-checked");
  //     isSetColor=(this.refBtnPre._elementRef.nativeElement.classList).contains("setColor");
  //     if(isChecked)
  //     {
  //       this.refBtnPre._elementRef.nativeElement.classList.remove("mat-button-toggle-checked")
  //     }
  //     if(isSetColor)
  //     {
  //       this.refBtnPre._elementRef.nativeElement.classList.remove("setColor")
  //     }
  //   }
  //   else
  //   {
  //     this.disBtnPre=false;
  //     this.refBtnPre._disabled= false;
  //     this.refBtnPre._checked=false;
  //     this.refBtnPre._disableRipple=false;
  //     this.refBtnPre._appearance="standard";
  //     this.refBtnPre._elementRef.nativeElement.classList.add("setColor");

  //     this.disabledNext=false;
  //     this.refBtnNext._disabled= false;
  //     this.refBtnNext._checked=false;
  //     this.refBtnNext._disableRipple=false;
  //     this.refBtnNext._appearance="standard";
  //     this.refBtnNext._elementRef.nativeElement.classList.add("setColor");
      
  //   }
  // }

  listType: any = [
    {
        "title": "ទាំងអស់",
        "titleEn": "All",
        "titleFr": "Tous"
    },
    {
      "title": "កិច្ចប្រជុំ",
      "titleEn": "Meeting",
      "titleFr": "Réunion"
    },
    {
      "title": "បុណ្យជាតិ និងអន្តរជាតិ",
      "titleEn": "National and international festivals",
      "titleFr": "Festivals nationaux et internationaux"
    },
    {
      "title": "លិខិតអបអរសាទរ",
      "titleEn": "Congratulatory letter",
      "titleFr": "Lettre de félicitations"
    },
    {
      "title": "បច្ចេកសព្ទប្រចាំថ្ងៃ",
      "titleEn": "Daily Vocabulary",
      "titleFr": "Vocabulaire quotidien"
    },
    {
      "title": "សមិទ្ធផល",
      "titleEn": "Achievements",
      "titleFr": "Réalisations"
    },
    {
      "title": "ផ្សេងៗ",
      "titleEn": "Others",
      "titleFr": "Autre"
    }

  ];
   
  //end disabledMatBtn
  toKhmerNumber(str){
    var khNumber = str.toString();

    khNumber = khNumber.replace(/0/g,'០')
    khNumber = khNumber.replace(/1/g,'១')
    khNumber = khNumber.replace(/2/g,'២')
    khNumber = khNumber.replace(/3/g,"៣")
    khNumber = khNumber.replace(/4/g,"៤")
    khNumber = khNumber.replace(/5/g,"៥")
    khNumber = khNumber.replace(/6/g,"៦")
    khNumber = khNumber.replace(/7/g,"៧")
    khNumber = khNumber.replace(/8/g,"៨")
    khNumber = khNumber.replace(/9/g,"៩")
    
    return khNumber
  }

  toKhmerMonth(str){
    var khNumber = str.toString();

    khNumber = khNumber.replace(/01/g,this.translate.data.MONTH_01)
    khNumber = khNumber.replace(/02/g,this.translate.data.MONTH_02)
    khNumber = khNumber.replace(/03/g,this.translate.data.MONTH_03)
    khNumber = khNumber.replace(/04/g,this.translate.data.MONTH_04)
    khNumber = khNumber.replace(/05/g,this.translate.data.MONTH_05)
    khNumber = khNumber.replace(/06/g,this.translate.data.MONTH_06)
    khNumber = khNumber.replace(/07/g,this.translate.data.MONTH_07)
    khNumber = khNumber.replace(/08/g,this.translate.data.MONTH_08)
    khNumber = khNumber.replace(/09/g,this.translate.data.MONTH_09)
    khNumber = khNumber.replace(/10/g,this.translate.data.MONTH_10)
    khNumber = khNumber.replace(/11/g,this.translate.data.MONTH_11)
    khNumber = khNumber.replace(/12/g,this.translate.data.MONTH_12)
    
    return khNumber
  }

  convertDate(date){
    let dateList,dateStr='';
    let dateLabel=['ថ្ងៃទី','ខែ','ឆ្នាំ'];
    dateList=date.split('-');
    dateList[1]=this.toKhmerMonth(dateList[1]);
    if(this.currentLanguage=='km'){
      for(let i=0;i<dateList.length;i++){
        dateList[i]=this.toKhmerNumber(dateList[i]);
        dateStr+=dateLabel[i]+dateList[i]+' ';
      }
    } else {
      for(let i=0;i<dateList.length;i++){
        dateStr+=dateList[i]+' ';
      }
    }
    return dateStr;
  }

  openDialog(news) {
    let str: String = news.images;
    let imgList = str.split(',');

    const dialogRef = this.dialog.open(Detail, {
      data: {
        title: news.title,
        english: news.english,
        french: news.french,
        thumb: news.thumb,
        imgs: imgList,
        khmer: news.khmer,
      },
    });
      dialogRef.afterClosed().subscribe(result => {});
  }
  
  ngOnInit() {
    this._swaggerApiService.postPageToken().subscribe(data=>
      {
        let dataResponse = data;

        // console.log(dataResponse);
        // console.log(dataResponse.statusCode );

        if(dataResponse.statusCode == 200){
          // console.log('data res == ', dataResponse);
          let uri = dataResponse.item.token.replace('created_time&limit=10', 'created_time&limit=100');
          // let uri = dataResponse.item.token;
          //call get new Feed from FB graph API
          
          this.getNewFeedFBpage(uri);
        }
      },(error:HttpResponse<Response>) => 
      {
        //get error response from FB
        // let errorMsg=error['error'].error.message;
        this._snackBar.open(error['error'], "OK", {
          duration: 2000
        });
      }
    );

    //** To call news/get_list in init with swagger API**
    // this.preNewsList=[]
    // this.key=new JSGenerateRSAKey();
    // var objBody=new ApiModel();
    // objBody.public_key=this.key.getPublicKey();
    // objBody.modified_date=new Date().toISOString();
    // objBody.limit=5;

    // this._swaggerApiService.postNewsGetLists(objBody)
    // .subscribe
    // (
    //   data=>
    //   {
    //     var objDecrypt=new ApiModel();
    //     objDecrypt.encryptedData=data;
    //     objDecrypt.private_key=this.key.getPrivateKey();
    //     this._swaggerApiService.postDecrypted(objDecrypt)
    //     .subscribe
    //     (
    //       data=>
    //       {          
    //         if(data.statusCode==200 && data.item.item.length>0){ //mean it is success
    //           let theNews=data.item.item;
    //           theNews.loadmore=data.item.item.length;
    //           data.item.item.forEach(element => {
    //             this.newsList.push(element);
    //           });
    //           this.preNewsList.push(theNews);
    //           this.totalRec=data.item.total;
    //           this.pageNum=this.preNewsList.length+"/"+this.totalRec;
    //           this.disabledMatBtn();
    //         }
    //         else if(data.statusCode==200 && data.item.item.length<=0)
    //         {
    //           //Toast message to users
    //           this._snackBar.open("oops! no more records found.", "OK", {
    //             duration: 2000
    //           });
    //         }
    //         else
    //         {
    //           //Toast message to users
    //           this._snackBar.open(data.message, "OK", {
    //             duration: 2000
    //           });
    //         }

    //       },(error:HttpResponse<Response>) => {
    //         let errorMsg=error['message'];
    //         this._snackBar.open("News-Init Decrypt Res_Err: "+errorMsg, "OK", {
    //           duration: 2000
    //         });
    //       }
    //     )
    //   },(error:HttpResponse<Response>) => {
    //     let errorMsg=error['message'];
    //     this._snackBar.open("News-Init Res_Err: "+errorMsg, "OK", {
    //       duration: 2000
    //     });
    //   }
    // )
    //**end news/get_list in init**
  }
  getNewFeedFBpage(uri){
        this.isLoading = true;
        //old FB Token request
        // this._swaggerApiService.getNewsFB("https://graph.facebook.com/v6.0/1528139114067649/feed?fields=message,permalink_url,full_picture,attachments,created_time&limit=10&access_token=EAAP4Crx1tZAUBANewu0wDgrhJNoMFDzQYHS164b442GrsbmcGzjthZA9WZBLwdMvUFjAGYToPC7rBPoyNhPcFlRKpowqhKZAwB5ZAbJ2yxwlY24juUHqR1MDRkrEfgLZAcCZCNqb7H3ljCEuy5cBz64RH0QUpOsDeHJ01uzLRNrS9Knk3Emrey9")
        this._swaggerApiService.getNewsFB(uri)
        .subscribe
        (
          response =>
          {
            //old FB Token request
            // let current_url="https://graph.facebook.com/v6.0/1528139114067649/feed?fields=message,permalink_url,full_picture,attachments,created_time&limit=10&access_token=EAAP4Crx1tZAUBANewu0wDgrhJNoMFDzQYHS164b442GrsbmcGzjthZA9WZBLwdMvUFjAGYToPC7rBPoyNhPcFlRKpowqhKZAwB5ZAbJ2yxwlY24juUHqR1MDRkrEfgLZAcCZCNqb7H3ljCEuy5cBz64RH0QUpOsDeHJ01uzLRNrS9Knk3Emrey9";
            
            let current_url = uri;
            if(response.data.length>0)
            {
              response.data.forEach(element => 
              {
                if(element.message!=undefined)
                { 
                  // element.message="កម្រងច្បាប់ខ្មែរពីបុរាណ ភាគ៣ និងភាគ៤\n----------\nជាសក្ខីកម្មប្រវត្តិសាស្ត្រជាតិបញ្ជាក់អំពីអត្ថិភាពនៃវប្បធម៌នីតិសាស្ត្រ ធម្មសាស្ត្រ និងយុត្តិធម៌របស់ខ្មែរ ដែលមានតាំងពីយូរលង់ណាស់មកហើយ គឺមុនពេលមកដល់នៃឥទ្ធិពលប្រព័ន្ធច្បាប់និងប្រព័ន្ធយុត្តិធម៌បារាំងទៅទៀត ដែលបន្តពីកម្រងច្បាប់ខ្មែរពីបុរាណ ភាគ១ និងភាគ២ ពោលគឺជាកម្រងច្បាប់ខ្មែរពីបុរាណឆ្នាំ១៨៩១។ \n|\nComplication of Khmer Ancient Law, Volume III and IV\n----------\nHistorical attestation of the Khmer legal, Dharma and judicial culture from the ancient time before the influence of the French culture and system. This Compilation is the sequel of Compilation of Khmer Ancient Law Part I and II, which are the compilation of laws in 1891. \n|\nCodes anciens du Cambodge (Volume 3 et Volume 4)\n----------\nLe Recueil des anciens codes khmers (2020) est un témoignage historique qui justifie l’existence de la culture juridique (de Nitisastra), de Dhammasastra et judiciaire (de la justice) khmère, depuis les temps anciens, avant que les systèmes juridique et judiciaire khmers ne subissent l’influence du système français. Ce Recueil a été produit à la suite de celui des anciens codes khmers de deux volumes (1891).";
                  let newsEl=element;
                  let sms=element.message.split("\n|\n");
                  if(sms[0].indexOf("\n----------\n")!=-1)
                  {
                    newsEl.titleKh=sms[0].split("\n----------\n")[0];
                    newsEl.khDes=sms[0].split("\n----------\n")[1];
                    
                    if(sms[1]!=undefined)
                    {
                    newsEl.titleEn=sms[1].split("\n----------\n")[0];
                    newsEl.enDes=sms[1].split("\n----------\n")[1];
                    }
                    if(sms[2]!=undefined)
                    {
                    newsEl.titleFr=sms[2].split("\n----------\n")[0];
                    newsEl.frDes=sms[2].split("\n----------\n")[1];
                    }
                  }
                  else
                  {
                    newsEl.titleKh='';
                    newsEl.titleEn='';
                    newsEl.titleFr='';
      
                    newsEl.khDes=element.message;
                    newsEl.enDes=element.message;
                    newsEl.frDes=element.message;
                  }
                  newsEl.url=current_url;
                  this.newsListFB.push(newsEl);
                  
                }
              });
              this.listDisplay = this.newsListFB;

              this.paging = response.paging;
              let newsPreEl:any = this.newsListFB;
              newsPreEl.paging = this.paging;
              this.preNewsListFB.push(newsPreEl);
              // console.info("paging = ",this.paging.previous);
            }
            else
            {
              this._snackBar.open("oops! no more records found.", "OK", {
                duration: 2000
              });
            }
            // console.info("response = ",response);
            this.isLoading = false;
            // this.disabledMatBtnFB();
          },(error:HttpResponse<Response>) => 
            {
              //get error response from FB
              let errorMsg=error['error'].error.message;
              this._snackBar.open(errorMsg, "OK", {
                duration: 2000
              });
            }
        );
  }
  //display date new of category
  yourFn(event) { 
    if(event.index == 0) { 
      this.listDisplay = this.newsListFB
    } else if(event.index == 1) {
        this.listDisplay = this.newsListFB.filter(item => {
        return item.titleKh.toLowerCase().includes('កិច្ចប្រជុំ'.toLowerCase());
      });    
      // console.log('listDisplay  ===', this.listDisplay);
    } else if(event.index == 2) {
        this.listDisplay = this.newsListFB.filter(item => {
        return item.titleKh.toLowerCase().includes('ព្រះរាជពិធី'.toLowerCase()) 
                || item.titleKh.toLowerCase().includes('បុណ្យវិសាខបូជា'.toLowerCase())
                || item.titleKh.toLowerCase().includes('ទិវារំលឹកខួប'.toLowerCase())
                || item.titleKh.toLowerCase().includes('បុណ្យភ្ជុំបិណ្ឌ'.toLowerCase())
                || item.titleKh.toLowerCase().includes('ប្រពៃណីជាតិខ្មែរ'.toLowerCase())
                || item.titleKh.toLowerCase().includes('ទិវាសិទ្ធិនារីអន្តរជាតិ'.toLowerCase())
      ;
      });
    } else if(event.index == 3) {
        this.listDisplay = this.newsListFB.filter(item => {
        return item.titleKh.toLowerCase().includes('លិខិតអបអរសាទរ'.toLowerCase());
      });
    } else if(event.index == 4) {
        this.listDisplay = this.newsListFB.filter(item => {
        return item.message.toLowerCase().includes('និយមន័យ នៃពាក្យ'.toLowerCase());
      });
    } else if(event.index == 5) {
        this.listDisplay = this.newsListFB.filter(item => {
        return item.khDes.toLowerCase().includes('លេខាធិការដ្ឋានក្រុមប្រឹក្សានីតិកម្មបានរៀបចំ និងដាក់ឱ្យប្រើប្រាស់រួចរាល់'.toLowerCase());
      });
    }
    else {
      this.listDisplay = this.newsListFB.filter(item => {
        return !item.titleKh.toLowerCase().includes('កិច្ចប្រជុំ'.toLowerCase()) 
        && !item.titleKh.toLowerCase().includes('ព្រះរាជពិធី'.toLowerCase())
        && !item.titleKh.toLowerCase().includes('លិខិតអបអរសាទរ'.toLowerCase())
        && !item.message.toLowerCase().includes('និយមន័យ នៃពាក្យ'.toLowerCase())
        && !item.khDes.toLowerCase().includes('លេខាធិការដ្ឋានក្រុមប្រឹក្សានីតិកម្មបានរៀបចំ និងដាក់ឱ្យប្រើប្រាស់រួចរាល់'.toLowerCase())
        ;
      });
    }
  }

  findLang(tool:any ) {
    if (this.currentLanguage == 'km' && (tool.title != null && tool.title != undefined && tool.title != '')) {
      return tool.title;
    }  
    else if (this.currentLanguage == 'en' && (tool.titleEn != null && tool.titleEn != undefined && tool.titleEn != '')) {
      return tool.titleEn;

    } else if (this.currentLanguage == 'fr' && (tool.titleFr != null && tool.titleFr != undefined && tool.titleFr != '')) {
      return tool.titleFr;

    }
    return tool.title;
  }

//<!.........>

  // openSocialLink(){
  //   alert('Hello link')
  // }

  onPaginateChange(event: any) {

   const pageSize = event.pageSize;
    this._swaggerApiService.postPageToken().subscribe(data=>
      {
        let dataResponse = data;
        if(dataResponse.statusCode==200){
          console.log('data res == ', dataResponse);
          //let uri = dataResponse.item.token.replace('created_time&limit=10', 'created_time&limit=', pageSize);
          //call get new Feed from FB graph API
          let uri = dataResponse.item.token.replace('created_time&limit=10', 'created_time&limit=100');
          console.log("URI: ", uri);
          
          this.getNewFeedFBpage(uri);
        }
      },(error:HttpResponse<Response>) => 
      {
        //get error response from FB
        let errorMsg=error['error'].error.message;
        this._snackBar.open(errorMsg, "OK", {
          duration: 2000
        });
      }
    );
  }

  // filterDocs(pageIndex: any, limited: any) {
  //   this.newsList = [];
  //   this.getServerLaws(this.textSearch, (pageIndex + 1), limited);
  // }
}

@Component({
  selector: 'detail',
  templateUrl: 'detail.html',
})
export class Detail {
  constructor(
    public dialogRef: MatDialogRef<Detail>,
    @Inject(MAT_DIALOG_DATA) public data: DetailData,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
