import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApiModel} from '../classes/api_model'
import {Posts} from '../classes/posts'

@Injectable()
export class swaggerApiService
{
    constructor(private httpClient:HttpClient){}

    postPageToken():Observable<any>{
        return this.httpClient.post("https://lexicon.legalcouncilmef.com/dev/page-access-token/get_page_token/",{type:'legal_council_page'},{responseType: 'json'});
    }

    postOtherTab(objBody:ApiModel):Observable<any>{
        // console.log("objBody ==", objBody);

        return this.httpClient.post("https://app.legalcouncilmef.com/other_tab/get_list/",objBody,{responseType: 'json'});
    }

    getOtherTabCategory():Observable<any>{
        return this.httpClient.get("https://app.legalcouncilmef.com/other_tab_category/",{responseType: 'json'});
    }

    postDMISCategory(cate:boolean):Observable<any>{
        return this.httpClient.get("https://dmis.legalcouncilmef.com/api/type_of_legal/"+cate,{responseType: 'json'});
    }

    postDMISDocuments(objBody:ApiModel):Observable<any>{
        return this.httpClient.post("https://dmis.legalcouncilmef.com/api/law/get_list_encrypt",objBody,{responseType: 'text'});
    }

    getNewsFB(uri): Observable<any> {   
        // this.http refers to HttpClient. Note here that you cannot use the generic get<Blob> as it does not compile: instead you "choose" the appropriate API in this way.
        return this.httpClient.get(uri, { responseType: 'json' });
    }

   

    getPDF(uri): Observable<Blob> {   
        // this.http refers to HttpClient. Note here that you cannot use the generic get<Blob> as it does not compile: instead you "choose" the appropriate API in this way.
        return this.httpClient.get(uri, { responseType: 'blob' });
    }

    postELibrary(objBody:ApiModel):Observable<any>{
        return this.httpClient.post("https://app.legalcouncilmef.com/library/get_all_elib",objBody,{responseType: 'text'});
    }

    postELibraryTypes(objBody:ApiModel):Observable<any>{
        return this.httpClient.post("https://app.legalcouncilmef.com/library/get_all_elib_v2",objBody,{responseType: 'text'});
    }

    postOtherDoc(objBody:ApiModel):Observable<any>{
        return this.httpClient.post("https://app.legalcouncilmef.com/otherdoc/get_list",objBody,{responseType: 'text'});
    }

    postEduvideo(objBody:ApiModel):Observable<any>{
        return this.httpClient.post("https://app.legalcouncilmef.com/eduvideo/get_list",objBody,{responseType: 'text'});
    }

    postCategory(objBody:ApiModel):Observable<any>{
        return this.httpClient.post("https://app.legalcouncilmef.com/category/get_list",objBody,{responseType: 'text'});
    }

    postCarousel(objBody:ApiModel):Observable<any>{
        return this.httpClient.post("https://app.legalcouncilmef.com/sliding/get_list",objBody,{responseType: 'text'});
    }

    postGetLibraryId(objBody:ApiModel):Observable<any>{
        return this.httpClient.post("https://app.legalcouncilmef.com/library/find_id",objBody,{responseType: 'text'});
    }

    postGetNewsId(objBody:ApiModel):Observable<any>{
        return this.httpClient.post("https://app.legalcouncilmef.com/news/find_id",objBody,{responseType: 'text'});
    }

    postLibraryGetLists(objBody:ApiModel):Observable<any>{
        return this.httpClient.post("https://app.legalcouncilmef.com/library/get_list",objBody,{responseType: 'text'});
    }
    postNewsGetLists(objBody:ApiModel):Observable<any>{
        return this.httpClient.post("https://app.legalcouncilmef.com/news/get_list",objBody,{responseType: 'text'});
    }
    postDecrypted(objBody:ApiModel):Observable<any>{
        return this.httpClient.post("https://app.legalcouncilmef.com/encrypt/decrypted",objBody,{responseType: 'json'});
    }
    posts(objPost:Posts):Observable<any>{
        return this.httpClient.post("https://jsonplaceholder.typicode.com/posts",objPost);

    }
}
