import { Component, OnInit,Input} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from "@angular/fire/firestore";
import { TranslateService } from "./../translate.service";
import {ApiModel} from "../classes/api_model";
import {swaggerApiService} from "../services/swaggerApi.service";
import {JSGenerateRSAKey} from "../services/JSGenerateRSAKey";
import {MatSnackBar} from "@angular/material/snack-bar";
import { element } from 'protractor';
import { HttpResponse } from '@angular/common/http';
import { time } from 'console';

@Component({
  selector: 'app-read-details',
  templateUrl: './read-details.component.html',
  styleUrls: ['./read-details.component.css']
})
export class ReadDetailsComponent implements OnInit {
  data:any;
  newsList=[];
  readAllList=[];
  selectedNews='';
  sub: any;
  test: any;
  test1: any;
  carouselList = [];
  length = 0;
  pageSize = 0;
  selectedTab = -1;
  controller:string;
  btnRecently:any={};
  key:JSGenerateRSAKey;
  isLoading = false;
 
  @Input() currentLanguage: string;
  constructor(private db: AngularFirestore,private router: Router, private route: ActivatedRoute,private translate: TranslateService,private _swaggerApiService:swaggerApiService,private __snackBar:MatSnackBar) {
    this.route.params.subscribe( params => this.onReadDetailsFB(params));
   }

   onLoadReadDetails(newsID,collection){
    this.controller=collection;
    if(this.controller=='compendium'){
      this.btnRecently.index=1;
      this.btnRecently.title="RECENT_LCS_TOOLS";

    }else if(this.controller=='article') {
      this.btnRecently.index=3;
      this.btnRecently.title="EVENTS";
    }
    this.newsList=[];
    this.db
    .collection("carousel")
    .get()
    .subscribe(queyrSnap => {
      queyrSnap.forEach(doc => {
        this.carouselList.push(doc.data());
      });
    });
  this.db
    .collection(collection, ref => ref.orderBy("create", "desc").limit(5))
    .get()
    .subscribe(queyrSnap => {
      queyrSnap.forEach(doc => {
        let docId = doc.id;
        let theDoc = doc.data();

     
        //firestore.Timestamp.fromDate(jsDate
        let theDate = theDoc["create"];
        console.log("you will done it : " + theDate.seconds);
        //
        let str: String = theDoc.images;
        let imgs = str.split(",");
        theDoc.imgs = imgs;
        theDoc.newsId = docId;
        this.newsList.push(theDoc);
      });

      this.length = this.newsList.length;
      this.pageSize = 4;
      // this.searchNews(newsID);
    });
    this.goTopFunction();
   }
   onReadDetailsFB(params){
    if(this.newsList.length>0)
    {
      return;
    }
    this.isLoading=true;
    let collection=params["collection"];
    let newsID=params["newsId"];

    let objBody=new ApiModel();
    this.controller=collection;
    this.key=new JSGenerateRSAKey();
    objBody.public_key=this.key.getPublicKey();
    objBody.modified_date=new Date().toISOString();
    objBody.limit=100;

    if(this.controller=='compendium'){
      this.btnRecently.index=1;
      this.btnRecently.title="RECENT_LCS_TOOLS";

      this._swaggerApiService.postLibraryGetLists(objBody)
        .subscribe
        (
          data=>
          {
            let objDecrypt=new ApiModel();
            objDecrypt.private_key=this.key.getPrivateKey();
            objDecrypt.encryptedData=data;
            this._swaggerApiService.postDecrypted(objDecrypt)
              .subscribe
              (
                data=>
                {
                  if(data.statusCode==200 && data.item.length>0)
                  {
                    let temp=[];
                    this.newsList=[];
                    this.readAllList=[];
                    data.item.forEach
                    (
                      element => 
                      {
                        element.item.forEach(value => 
                        {
                          temp.push(value);
                          this.readAllList.push(value);
                        });
                      }
                    );

                    temp.sort
                    (
                      function(a,b)
                      {
                        return a.publishAt <b.publishAt?1:a.publishAt >b.publishAt?-1:0;
                      }
                    );
                    for(let i=0;i<5;i++)
                    {
                      this.newsList.push(temp[i]);
                    }
                    this.searchNewsLib(newsID);
                  }
                  else if(data.statusCode==200 && data.item.length<=0)
                  {
                    //Toast message to users
                    this.__snackBar.open("oops! no records found.", "OK", {
                      duration: 2000
                    });
                  }
                  else
                  {
                    //Toast message to users
                    this.__snackBar.open(data.message, "OK", {
                      duration: 2000
                    });
                  }
                  this.isLoading=false;
                },(error:HttpResponse<Response>) => {
                  this.isLoading=false;
                  let errorMsg=error['message'];
                  this.__snackBar.open("LibraryGetLists Decrypted Res_Err: "+errorMsg, "OK", {
                    duration: 2000
                  });
                }
              )
              this.isLoading=false;
          },(error:HttpResponse<Response>) => {
            this.isLoading=false;
            let errorMsg=error['message'];
            this.__snackBar.open("LibraryGetLists Res_Err: "+errorMsg, "OK", {
              duration: 2000
            });
          }
        )

    }else if(this.controller=='article') {
      this.btnRecently.index=3;
      this.btnRecently.title="EVENTS";
      let par=params["par"].replace(/@@/g,'=');
      let url_fb = "https://graph.facebook.com/"+params["version"]+'/'+params["page_id"]+'/'+params["page_type"]+'?'+par;

      this._swaggerApiService.postPageToken().subscribe(data=>
        {
          let Datares=data;
          if(Datares.statusCode==200){
            let uri=Datares.item.token;
            //call get new Feed from FB graph API
            this.getNewFeedFBpage(uri,url_fb,newsID);
          }
        },(error:HttpResponse<Response>) => 
        {
          //get error response from FB
          let errorMsg=error['error'].error.message;
          this.__snackBar.open(errorMsg, "OK", {
            duration: 2000
          });
        }
      );
    }
    this.goTopFunction();
  }
  getNewFeedFBpage(uri,url_fb,newsID){
          //old FB Token request
          // this._swaggerApiService.getNewsFB("https://graph.facebook.com/v6.0/1528139114067649/feed?fields=message,permalink_url,full_picture,attachments,created_time&limit=10&access_token=EAAP4Crx1tZAUBANewu0wDgrhJNoMFDzQYHS164b442GrsbmcGzjthZA9WZBLwdMvUFjAGYToPC7rBPoyNhPcFlRKpowqhKZAwB5ZAbJ2yxwlY24juUHqR1MDRkrEfgLZAcCZCNqb7H3ljCEuy5cBz64RH0QUpOsDeHJ01uzLRNrS9Knk3Emrey9")
          this._swaggerApiService.getNewsFB(uri)
          .subscribe
          (
            response =>
            {
              //old FB Token request
              // let current_url="https://graph.facebook.com/v6.0/1528139114067649/feed?fields=message,permalink_url,full_picture,attachments,created_time&limit=10&access_token=EAAP4Crx1tZAUBANewu0wDgrhJNoMFDzQYHS164b442GrsbmcGzjthZA9WZBLwdMvUFjAGYToPC7rBPoyNhPcFlRKpowqhKZAwB5ZAbJ2yxwlY24juUHqR1MDRkrEfgLZAcCZCNqb7H3ljCEuy5cBz64RH0QUpOsDeHJ01uzLRNrS9Knk3Emrey9";
              let current_url=uri;
              if(response.data.length>0)
              {
                this.newsList=[];
                response.data.forEach(element => 
                {
                  if(element.message!=undefined)
                  { 
                    // element.message="កម្រងច្បាប់ខ្មែរពីបុរាណ ភាគ៣ និងភាគ៤\n----------\nជាសក្ខីកម្មប្រវត្តិសាស្ត្រជាតិបញ្ជាក់អំពីអត្ថិភាពនៃវប្បធម៌នីតិសាស្ត្រ ធម្មសាស្ត្រ និងយុត្តិធម៌របស់ខ្មែរ ដែលមានតាំងពីយូរលង់ណាស់មកហើយ គឺមុនពេលមកដល់នៃឥទ្ធិពលប្រព័ន្ធច្បាប់និងប្រព័ន្ធយុត្តិធម៌បារាំងទៅទៀត ដែលបន្តពីកម្រងច្បាប់ខ្មែរពីបុរាណ ភាគ១ និងភាគ២ ពោលគឺជាកម្រងច្បាប់ខ្មែរពីបុរាណឆ្នាំ១៨៩១។ \n|\nComplication of Khmer Ancient Law, Volume III and IV\n----------\nHistorical attestation of the Khmer legal, Dharma and judicial culture from the ancient time before the influence of the French culture and system. This Compilation is the sequel of Compilation of Khmer Ancient Law Part I and II, which are the compilation of laws in 1891. \n|\nCodes anciens du Cambodge (Volume 3 et Volume 4)\n----------\nLe Recueil des anciens codes khmers (2020) est un témoignage historique qui justifie l’existence de la culture juridique (de Nitisastra), de Dhammasastra et judiciaire (de la justice) khmère, depuis les temps anciens, avant que les systèmes juridique et judiciaire khmers ne subissent l’influence du système français. Ce Recueil a été produit à la suite de celui des anciens codes khmers de deux volumes (1891).";
                    let newsEl=element;
                    let sms=element.message.split("\n|\n");
                    if(sms[0].indexOf("\n----------\n")!=-1)
                    {
                      newsEl.titleKh=sms[0].split("\n----------\n")[0];
                      newsEl.khDes=sms[0].split("\n----------\n")[1];
                      newsEl.khDes=newsEl.khDes.replace(/\n/g,'<br/>');
                      if(sms[1]!=undefined)
                      {
                      newsEl.titleEn=sms[1].split("\n----------\n")[0];
                      newsEl.enDes=sms[1].split("\n----------\n")[1];
                      newsEl.enDes=newsEl.enDes.replace(/\n/g,'<br/>');
                      }
                      if(sms[2]!=undefined)
                      {
                      newsEl.titleFr=sms[2].split("\n----------\n")[0];
                      newsEl.frDes=sms[2].split("\n----------\n")[1];
                      newsEl.frDes=newsEl.frDes.replace(/\n/g,'<br/>');  
                      }
                    }
                    else
                    {
                      newsEl.titleKh='';
                      newsEl.titleEn='';
                      newsEl.titleFr='';
        
                      newsEl.khDes=element.message;
                      newsEl.enDes=element.message;
                      newsEl.frDes=element.message;
    
                      newsEl.khDes=newsEl.khDes.replace(/\n/g,'<br/>');
                      newsEl.enDes=newsEl.enDes.replace(/\n/g,'<br/>');
                      newsEl.frDes=newsEl.frDes.replace(/\n/g,'<br/>');  
                    }
                    newsEl.url=current_url
                    this.newsList.push(newsEl);
                  }
                });
                this.searchNewsFB(newsID,url_fb);
              }
              else
              {
                this.__snackBar.open("oops! no more records found.", "OK", {
                  duration: 2000
                });
              }
              this.isLoading=false;
            },(error:HttpResponse<Response>) => 
              {
                this.isLoading=false;
                //get error response from FB
                let errorMsg=error['error'].error.message;
                this.__snackBar.open(errorMsg, "OK", {
                  duration: 2000
                });
              }
          );
  }
  searchNewsLib(newsID)
  {
    let isHas=false;
    this.selectedNews=newsID;
    this.newsList.forEach(value => {
      if(value._id==newsID){
        this.data= value;
        isHas=true;
      }
    });

    if(isHas)
    {
      return;
    }
      let objBody=new ApiModel();
      this.key=new JSGenerateRSAKey();
      objBody.public_key=this.key.getPublicKey();
      objBody.id=newsID;
      objBody.modified_date=new Date().toISOString();
      if(this.controller=='compendium')
      {
        this.readAllList.forEach(value => 
          {
            if(value._id==newsID)
            {
              this.data=value;
              isHas=true;
            }
          }

        );
        if(!isHas)
        {
          this.__snackBar.open("oops! no compendium found.", "OK", {
            duration: 2000
          });
        }
      }
  }
  searchNewsFB(newsID,fb_url){
    let isHas=false;
    this.selectedNews=newsID;
    this.newsList.forEach(value => {
      if(value.id==newsID){
        this.data= value;
        isHas=true;
      }
    });

    if(isHas)
    {
      return;
    }

      if(this.controller=='article')
      {
        this.isLoading=true;
        this._swaggerApiService.getNewsFB(fb_url)
        .subscribe
        (
          response =>
          {
            let current_url=fb_url;
            if(response.data.length>0)
            {
              response.data.forEach(element => 
              {
                if(element.id==newsID && element.message!=undefined)
                {
                  // element.message="កម្រងច្បាប់ខ្មែរពីបុរាណ ភាគ៣ និងភាគ៤\n----------\nជាសក្ខីកម្មប្រវត្តិសាស្ត្រជាតិបញ្ជាក់អំពីអត្ថិភាពនៃវប្បធម៌នីតិសាស្ត្រ ធម្មសាស្ត្រ និងយុត្តិធម៌របស់ខ្មែរ ដែលមានតាំងពីយូរលង់ណាស់មកហើយ គឺមុនពេលមកដល់នៃឥទ្ធិពលប្រព័ន្ធច្បាប់និងប្រព័ន្ធយុត្តិធម៌បារាំងទៅទៀត ដែលបន្តពីកម្រងច្បាប់ខ្មែរពីបុរាណ ភាគ១ និងភាគ២ ពោលគឺជាកម្រងច្បាប់ខ្មែរពីបុរាណឆ្នាំ១៨៩១។ \n|\nComplication of Khmer Ancient Law, Volume III and IV\n----------\nHistorical attestation of the Khmer legal, Dharma and judicial culture from the ancient time before the influence of the French culture and system. This Compilation is the sequel of Compilation of Khmer Ancient Law Part I and II, which are the compilation of laws in 1891. \n|\nCodes anciens du Cambodge (Volume 3 et Volume 4)\n----------\nLe Recueil des anciens codes khmers (2020) est un témoignage historique qui justifie l’existence de la culture juridique (de Nitisastra), de Dhammasastra et judiciaire (de la justice) khmère, depuis les temps anciens, avant que les systèmes juridique et judiciaire khmers ne subissent l’influence du système français. Ce Recueil a été produit à la suite de celui des anciens codes khmers de deux volumes (1891).";
                  let newsEl=element;
                  let sms=element.message.split("\n|\n");
                  if(sms[0].indexOf("\n----------\n")!=-1)
                  {
                    newsEl.titleKh=sms[0].split("\n----------\n")[0];
                    newsEl.khDes=sms[0].split("\n----------\n")[1];
                    newsEl.khDes=newsEl.khDes.replace(/\n/g,'<br/>');
                    
                    if(sms[1]!=undefined)
                    {
                    newsEl.titleEn=sms[1].split("\n----------\n")[0];
                    newsEl.enDes=sms[1].split("\n----------\n")[1];
                    newsEl.enDes=newsEl.enDes.replace(/\n/g,'<br/>');
                    }
                    if(sms[2]!=undefined)
                    {
                    newsEl.titleFr=sms[2].split("\n----------\n")[0];
                    newsEl.frDes=sms[2].split("\n----------\n")[1];
                    newsEl.frDes=newsEl.frDes.replace(/\n/g,'<br/>');
                    }
                  }
                  else
                  {
                    newsEl.titleKh='';
                    newsEl.titleEn='';
                    newsEl.titleFr='';
      
                    newsEl.khDes=element.message;
                    newsEl.enDes=element.message;
                    newsEl.frDes=element.message;

                    newsEl.khDes=newsEl.khDes.replace(/\n/g,'<br/>');
                    newsEl.enDes=newsEl.enDes.replace(/\n/g,'<br/>');
                    newsEl.frDes=newsEl.frDes.replace(/\n/g,'<br/>');
                  }
                  isHas=true;
                  newsEl.url=current_url;
                  this.data=newsEl;
                }
              });
              if(!isHas)
              {
                this.__snackBar.open("oops! no news found.", "OK", {
                  duration: 2000
                });
              }
            }
            else
            {
              this.__snackBar.open("oops! no more records found.", "OK", {
                duration: 2000
              });
            }
            this.isLoading=false;
          },(error:HttpResponse<Response>) => 
            {
              this.isLoading=false;
              //get error response from FB
              let errorMsg=error['error'].error.message;
              this.__snackBar.open(errorMsg, "OK", {
                duration: 2000
              });
            }
        );
      }
  }

   readEvents(news){
    this.router.navigate(['details/',this.controller,news._id,'YES',this.currentLanguage]);
    this.newsList=[];
   }
   readEventsFB(news){
     let url=news.url.replace("https://graph.facebook.com/",'');
     url=url.split("/");
     let parameter=url[2].split("?");
     parameter[1]=parameter[1].replace(/=/g,'@@');
    this.router.navigate(['details/',this.controller,news.id,'YES',url[0],url[1],parameter[0],parameter[1],this.currentLanguage]);
    this.newsList=[];
   }
   goTopFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  onMenuClicked(index){
    this.router.navigate(['contents',index,this.currentLanguage]);
  }
  toKhmerNumber(str){
    var khNumber = str.toString();

    khNumber = khNumber.replace(/0/g,'០')
    khNumber = khNumber.replace(/1/g,'១')
    khNumber = khNumber.replace(/2/g,'២')
    khNumber = khNumber.replace(/3/g,"៣")
    khNumber = khNumber.replace(/4/g,"៤")
    khNumber = khNumber.replace(/5/g,"៥")
    khNumber = khNumber.replace(/6/g,"៦")
    khNumber = khNumber.replace(/7/g,"៧")
    khNumber = khNumber.replace(/8/g,"៨")
    khNumber = khNumber.replace(/9/g,"៩")
    
    return khNumber
  }
  toKhmerMonth(str){
    var khNumber = str.toString();

    khNumber = khNumber.replace(/01/g,this.translate.data.MONTH_01)
    khNumber = khNumber.replace(/02/g,this.translate.data.MONTH_02)
    khNumber = khNumber.replace(/03/g,this.translate.data.MONTH_03)
    khNumber = khNumber.replace(/04/g,this.translate.data.MONTH_04)
    khNumber = khNumber.replace(/05/g,this.translate.data.MONTH_05)
    khNumber = khNumber.replace(/06/g,this.translate.data.MONTH_06)
    khNumber = khNumber.replace(/07/g,this.translate.data.MONTH_07)
    khNumber = khNumber.replace(/08/g,this.translate.data.MONTH_08)
    khNumber = khNumber.replace(/09/g,this.translate.data.MONTH_09)
    khNumber = khNumber.replace(/10/g,this.translate.data.MONTH_10)
    khNumber = khNumber.replace(/11/g,this.translate.data.MONTH_11)
    khNumber = khNumber.replace(/12/g,this.translate.data.MONTH_12)
    
    return khNumber
  }
  convertYear(year){
    let yearStr=year;
    if(this.currentLanguage=='km'){
      yearStr=this.toKhmerNumber(year);
    }
    return yearStr;
  }

  convertDate(date){
    let dateList,dateStr='';
    let dateLabel=['ថ្ងៃទី','ខែ','ឆ្នាំ'];
    dateList=date.split('-');
    dateList[1]=this.toKhmerMonth(dateList[1]);
    if(this.currentLanguage=='km'){
      for(let i=0;i<dateList.length;i++){
        dateList[i]=this.toKhmerNumber(dateList[i]);
        dateStr+=dateLabel[i]+dateList[i]+' ';
      }
    } else {
      for(let i=0;i<dateList.length;i++){
        dateStr+=dateList[i]+' ';
      }
    }
    return dateStr;
  }
  ngOnInit() {
    // this.stateService.setLanguage(undefined);
    // this.data = JSON.parse(localStorage.getItem('data'));
    // this.newsList=JSON.parse(localStorage.getItem('newsList'));
    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     // Defaults to 0 if no query param provided.
    //     console.info('ngOnInit-read Detail');
    //     this.data = JSON.parse(params['data']);
    //     this.newsList = JSON.parse(params['newsList']);
    //   });
    //   console.info("test",this.test);
    //   console.info("data",this.data);
      // console.info("newsList",this.test1);
  }
  // ngOnDestroy() {
  //   this.sub.unsubscribe();
  // }
 //******************************** */
  openSocialLink(desc:string){
    alert(desc);
  
  }

}
export interface DetailData {
  title: string;
  english: string;
  french: string;
  thumb: string;
  khmer: string;
  imgs: [];
  date: string;
}
export interface NewsList{
  title: string;
  thumb: string;
  khmer: string;
  create: Date;
}
