import { Component, OnInit,Input,ElementRef,  ViewChild, HostListener} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import {swaggerApiService } from "../services/swaggerApi.service";
import { JSGenerateRSAKey } from "../services/JSGenerateRSAKey";
import { ApiModel } from "../classes/api_model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { templateJitUrl } from '@angular/compiler';
import { HttpResponse } from '@angular/common/http';


@Component({
  selector: 'app-it',
  templateUrl: './it.component.html',
  styleUrls: ['./it.component.css']
})

export class ItComponent implements OnInit {
  sticky: boolean = false;
  stickys: boolean = false;
  scrolltop:any=false;
  show: any = true;
  scrollNumber: any = 0;
  deviceInfo = null;
  isMobile:boolean;
  isTablet:boolean;
  isDesktopDevice:boolean;
  isLoading = false;
  // isDeviceService:boolean;

  listOther=[]; 
  listApp=[]; 
  tempOther=[];
  listELib=[];
  dataLiType:any = [];
  listVisionandStrategy = [];

  list1 = [];
  list2 = [];

  numIndex=0;
  @Input("lang") currentLanguage: string = "";

  @ViewChild("stickyMenu",{ static: false }) menuElement;
  // @ViewChild('menuElement', { static: true }) menuElementRef: ElementRef;
  // menuPosition: number;
  
  mobile_sidenav=0; //set margin-top of menu on SM, XS screen size

  key:JSGenerateRSAKey;
  constructor(private deviceService: DeviceDetectorService,private _swaggerApiService:swaggerApiService,private _snackBar:MatSnackBar) {
    this.epicFunction();
   }

  ngOnInit() {
    this.key=new JSGenerateRSAKey();

    let objIT=new ApiModel();
    this.isLoading = true;
    objIT.public_key=this.key.getPublicKey();
    objIT.modified_date=new Date().toISOString();
    objIT.limit=100;
    this._swaggerApiService.postELibrary(objIT)
      .subscribe
      (
        data=>
        {
          let objDecrypt=new ApiModel();
          objDecrypt.private_key=this.key.getPrivateKey();
          objDecrypt.encryptedData=data;
          this._swaggerApiService.postDecrypted(objDecrypt)
            .subscribe
            (
              data=>
              {
                if(data.statusCode==200 && data.item.length>0)
                {
                  // console.info("data ELib= ",data);
                  data.item.forEach(element => 
                    {
                      
                      // if(element.title=="apps")            
                      // {
                      //   element.item.forEach(value=>
                      //     {
                      //       this.listOther.push(value);
                      //       this.isLoading = false;
                      //     }
                      //   );
                      // }
                      // else if(element.title=="Guides")
                      // {
                      //   element.item.forEach(value=>
                      //     {
                      //       this.listELib.push(value);
                      //     }
                      //   )
                      // }

                    }
                  );
                  // if(this.listOther.length%4==0)
                  // {
                  //   this.numIndex=0;
                  //   for(let i=0;i<this.listOther.length;i++)
                  //   {
                  //     if(i!=0){
                  //       this.tempOther.push(this.listOther[i]);
                  //     }
                  //     //console.log("dd: ", this.tempOther, this.numIndex)
                  //   }
                  // }
                  // else
                  // {
                  //   this.numIndex=this.listOther.length%4;
                  //   if(this.listOther.length>4)
                  //   {
                  //     for(let i=this.numIndex;i<this.listOther.length;i++)
                  //     {
                  //       this.tempOther.push(this.listOther[i]);
                  //       //console.log("dd2: ", this.tempOther, this.numIndex)
                  //     }
                  //   }
                  //   // console.info("listELib",this.listELib);
                  //   // console.info("listOther",this.listOther);
                  // }
                  
                }
                else if(data.statusCode==200 && data.item.length<=0)
                {
                  //Toast message to users
                  this._snackBar.open("oops! no more records found.", "OK", {
                    duration: 2000
                  });
                }
                else
                {
                  //Toast message to users
                  this._snackBar.open("Category : "+data.item, "OK", {
                    duration: 2000
                  });
                }
              },(error:HttpResponse<Response>) => {
                let errorMsg=error['message'];
                this._snackBar.open("E-Library Decrypt Res_Err: "+errorMsg, "OK", {
                  duration: 2000
                });
              }
            )
        },(error:HttpResponse<Response>) => {
          let errorMsg=error['message'];
          this._snackBar.open("E-Library Res_Err: "+errorMsg, "OK", {
            duration: 2000
          });
        }
      )

    // type libray
    this._swaggerApiService.postELibraryTypes(objIT)
      .subscribe
      (
        data=>
        {
          let objDecrypt=new ApiModel();
          objDecrypt.private_key=this.key.getPrivateKey();
          objDecrypt.encryptedData=data;
          this._swaggerApiService.postDecrypted(objDecrypt)
            .subscribe
            (
              data=>
              {
                if(data.statusCode==200 && data.item.length>0)
                {
                  this.dataLiType = data.item;

                  this.isLoading = false;

                  console.log("data.item", this.dataLiType);

                  // data.item.forEach(value=>
                  //   {
                  //     // console.log(value.title);
                  //     if(value.title == "សទ្ទានុក្រម") {
                  //       this.list1.push(value.item);
                  //     }
                  //     // console.log(this.list1);
                  //     if(value.title == "កម្មវិធីលើទូរសព្ទ") {
                  //       this.listApp = this.list1[0].concat(value.item);
                  //     }
                  //   }
                  // );
                  // this.listApp.splice(1, 1);
                  // console.log(this.listApp);
                }
                else if(data.statusCode==200 && data.item.length<=0)
                {
                  //Toast message to users
                  this._snackBar.open("oops! no more records found.", "OK", {
                    duration: 2000
                  });
                }
                else
                {
                  //Toast message to users
                  this._snackBar.open("Category : "+data.item, "OK", {
                    duration: 2000
                  });
                }
              },(error:HttpResponse<Response>) => {
                let errorMsg=error['message'];
                this._snackBar.open("E-Library Decrypt Res_Err: "+errorMsg, "OK", {
                  duration: 2000
                });
              }
            )
        },(error:HttpResponse<Response>) => {
          let errorMsg=error['message'];
          this._snackBar.open("E-Library Res_Err: "+errorMsg, "OK", {
            duration: 2000
          });
        }
      )
  }
  
  ngAfterViewInit() {
    // this.menuPosition = this.menuElement._elementRef.nativeElement.offsetTop;
  }

  //on scroll event we add sticky class;
  @HostListener('window:scroll', ['$event'])
    handleScroll(){
        const windowScroll = window.pageYOffset;
        this.scrollNumber = windowScroll
       
        // if( windowScroll >= 120){
        //   this.sticky = true;
        // }
        // else{
        //   this.sticky = false
        //   this.stickys = false
        // }
    }
  //end scroll dock on top

  //function link to iOS,android,desktop,huawei
  openAppStore(appId)
  {
    let appStore='https://apps.apple.com/kh/app/id'+appId;
    window.open(appStore);
  }
  openPlayStore(playId)
  {
    let playStore='https://play.google.com/store/apps/details?id='+playId;
    window.open(playStore);

    console.log("this.deviceService: ", playStore)
  }    

  appGalleryLink: string = "https://appgallery.huawei.com/app/";

  openappGallery(appGalleryId)
  {
    console.log('appGalleryId', appGalleryId);
    if(appGalleryId == "សទ្ទានុក្រមគតិយុត្តសម្រាប់ក្រសួងសេដ្ឋកិច្ចនិងហិរញ្ញវត្ថុ") {
      let appGallery = this.appGalleryLink + "C107206433";
      window.open(appGallery);

    }
    //  else if (appGalleryId == "មរតកយុត្តិធម៌ខ្មែរ") {
    //   let appGallery= this.appGalleryLink + "C107225183";
    //   window.open(appGallery);

    // } else if(appGalleryId == "ប្រវត្តិការណ៍មួយនៃកម្ពុជា") {
    //   let appGallery=  this.appGalleryLink + "C107225231";
    //   window.open(appGallery);

    // } 
    else {//កម្មវិធីបំប្លែងរូបភាពទៅអក្សរ

    }
  }  
 
  openCompendium(comp, appId, playId,appGalleryId)
  {
    let compLink=comp;

    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    
    
    let appStore='https://apps.apple.com/kh/app/id'+appId;
    let playStore='https://play.google.com/store/apps/details?id='+playId;
    let appGallery='https://appgallery.huawei.com/app/'+appGalleryId;

    // console.log("this.deviceService: ", this.deviceService, appGalleryId)

    if (this.isDesktopDevice) {
      if (this.deviceService.os === 'Mac') {
        window.open(appStore);
      } else if(this.deviceService.os === 'Window') {
        window.open(playStore);
      }  
    }

    if(this.isMobile){
      if (this.deviceService.os === 'IOS') {
        window.open(appStore);
      }
      else{
        window.open(playStore);
        window.open(appGallery);
      }
    }
   
  }

  openCompendiumOpenPdf(linkOnlineCom:any, linkIos:any, linkAndroid:any)
  {
    console.log("linkIos  == ", linkIos);
    console.log("linkAndroid  == ", linkAndroid);


    if(linkOnlineCom == null){
      console.log('linkOnlineCom is empty');
    }
    
    // if(linkIos == "1520163547" || linkIos == "1496292354") {
    //   let appStore='https://apps.apple.com/kh/app/id' + linkIos;
    //   window.open(appStore);
    // } else {
      let compLink=linkOnlineCom;
      window.open(compLink);
    // }
  }


  toKhmerNumber(str){
    var khNumber = str.toString();

    khNumber = khNumber.replace(/0/g,'០')
    khNumber = khNumber.replace(/1/g,'១')
    khNumber = khNumber.replace(/2/g,'២')
    khNumber = khNumber.replace(/3/g,"៣")
    khNumber = khNumber.replace(/4/g,"៤")
    khNumber = khNumber.replace(/5/g,"៥")
    khNumber = khNumber.replace(/6/g,"៦")
    khNumber = khNumber.replace(/7/g,"៧")
    khNumber = khNumber.replace(/8/g,"៨")
    khNumber = khNumber.replace(/9/g,"៩")
    
    return khNumber
  }

  convertYear(year){
    let yearStr=year;
    if(this.currentLanguage=='km') {
      yearStr=this.toKhmerNumber(year);
    }
    return yearStr;
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
   
    // console.log(this.deviceInfo);
    // console.log("From Mobile = ",this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log("From Tablet = ",this.isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log("From Desktop = ",this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  goToLibAlbum(){
    var elmnt = document.getElementById("libAlbum");
    elmnt.scrollIntoView();
  }

//header កម្រងសម្រង់
  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  
//header ផ្សេង
  handletop(){
    document.body.scrollTop = 1560;
    document.documentElement.scrollTop = 1560;
  }


  
}
