import { DetailData } from "./../news/news.component";
import { Component, ViewChild,Input } from "@angular/core";
import {swaggerApiService} from "../services/swaggerApi.service";
import { ApiModel } from '../classes/api_model';
import {JSGenerateRSAKey} from '../services/JSGenerateRSAKey';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatTableDataSource } from "@angular/material/table";
import { HttpResponse } from '@angular/common/http';
import { log } from "console";

export interface DocData {
  id: number;
  originalId: string;
  name: string;
  date: string;
  down: string;
}
@Component({
  selector: "app-docs",
  templateUrl: "./docs.component.html",
  styleUrls: ["./docs.component.css"]
})
export class DocsComponent {
  labelList = [
    {name:"ALL",id:"5f166f61a2bbab62721d2ac5"},
    {name:"LAW",id:"5f166f77a2bbab62721d2ac6"},
    {name:"ROYAL_DECREES",id:"5f166faaa2bbab62721d2ac7"},
    {name:"SUB_DECREES",id:"5f167322a2bbab62721d2ac8"},
    {name:"GOVERNMENT_DECISION",id:"5f16736aa2bbab62721d2ac9"},
    {name:"GOVERNMENT_CIRCULARS",id:"5f167389a2bbab62721d2aca"},
    {name:"DECLARATIONS",id:"5f1673c2a2bbab62721d2acb"},
    {name:"MINISTRY_DECISIONS",id:"5f1673dba2bbab62721d2acc"},
    {name:"MINISTRY_CIRCULARS",id:"5f1673f6a2bbab62721d2acd"},
    {name:"OTHERS",id:"5f167409a2bbab62721d2ace"}
  ];
  key:JSGenerateRSAKey;
  listDocs=[];
  itemCount=0;
  categoryList=[];
  categoryID:string='';
  @Input("currentLanguage") currentLanguage: string = "";
  oldRequest:string;
  isLoading = false;

  dataSource: MatTableDataSource<DocData>;
  constructor(private _swaggerApiService:swaggerApiService, private _snackBar: MatSnackBar) {}
  ngOnInit() 
  {
    this.key=new JSGenerateRSAKey();
   

    //Use DMIS : type_of_legal API with GET
    this._swaggerApiService.postDMISCategory(true)
    
      .subscribe
      (
        data=>
        {
          if(data['message']=="success")
          {
                if(data.item.length>0 && data.item!="Unauthorized")
                {
                  data.item.forEach(element => 
                    {
                      this.categoryList.push(element);
                     
                    }
                  );
                }
                else if( data.statusCode==200 && data.item.length<=0)
                {
                  console.info("oops! no more records found.");
                }
                else
                {
                  //Toast message to users
                  this._snackBar.open("Category : "+data.item, "OK", {
                    duration: 2000
                  });
                }
          }
        },(error:HttpResponse<Response>) => {
          let errorMsg=error['message'];
          this._snackBar.open("Category Res_Err: "+errorMsg, "OK", {
            duration: 2000
          });
        }
      )
  }
  otherDocs(event){
    //to get category_id from mat-tab
    let category_id:string='';
    
    category_id=event.tab._viewContainerRef._view.component.categoryList[event.index]._id;
    let category_name=event.tab._viewContainerRef._view.component.categoryList[event.index].nameKh;
    if(category_name=='ទាំងអស់')
    {
      category_id='all';
    }
    this.categoryID=category_id;
    this.loadDocs(category_id);  
  }
  loadDocs(category_id)
  {
    this.listDocs=[];
    this.key=new JSGenerateRSAKey();

    let objDocs=new ApiModel();

    objDocs.public_key=this.key.getPublicKey();
    objDocs.modified_date=new Date().toISOString();
    objDocs.category=category_id;
    objDocs.limit=20;
    objDocs.keyword='';
    objDocs.sortBy='';
    objDocs.isNext=1;
    objDocs.oldReq = '';
    this._swaggerApiService.postDMISDocuments(objDocs)
      .subscribe
      (
        data=>
        {
          let objDecrypt=new ApiModel();
          objDecrypt.private_key=this.key.getPrivateKey();
          objDecrypt.encryptedData=data;
          this._swaggerApiService.postDecrypted(objDecrypt)
            .subscribe
            (
              data=>
              {
                // console.info("docs123 = ",data);
                if(data.statusCode==200)
                {
                  if(data.item.total>0 && data.item!="Unauthorized")
                  {
                        data.item.data.item.forEach(item =>{
                          this.listDocs.push(item);
                        });
                        this.itemCount=data.item.total;
                        this.oldRequest=data.oldReq;
                  }
                  else if(data.statusCode==200 && data.item.total==0)
                  {
                      console.info("oops! no more records found.");
                  }
                  else
                  {
                    //Toast message to users
                    this._snackBar.open("OtherDocs : "+data.message, "OK", {
                      duration: 2000
                    });
                  }
                }                
              },(error:HttpResponse<Response>) => {
                let errorMsg=error['message'];
                this._snackBar.open("OtherDocs Decrypt Res_Err: "+errorMsg, "OK", {
                  duration: 2000
                });
              }
            )
        },(error:HttpResponse<Response>) => {
          let errorMsg=error['message'];
          this._snackBar.open("OtherDocs Res_Err: "+errorMsg, "OK", {
            duration: 2000
          });
        }
      )
  }
  findLang(cate){
    if(this.currentLanguage=='km' && (cate.nameKh!=null && cate.nameKh!=undefined && cate.nameKh!='')){
      return cate.nameKh;
    } else if (this.currentLanguage=='en' && (cate.nameEn!=null && cate.nameEn!=undefined && cate.nameEn!='')){
      return cate.nameEn;

    } else if (this.currentLanguage=='fr' && (cate.nameFr!=null && cate.nameFr!=undefined && cate.nameFr!='')){
      return cate.nameFr;
    }
    return cate.nameKh;
  }
}
