import { saveAs } from "file-saver";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AngularFirestore } from "@angular/fire/firestore";
import { Component, OnInit, ViewChild, Input,ElementRef } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AngularFireStorage } from "@angular/fire/storage";
import { DomSanitizer } from "@angular/platform-browser";
import {swaggerApiService} from "../services/swaggerApi.service";
import {JSGenerateRSAKey} from "../services/JSGenerateRSAKey";
import {ApiModel} from "../classes/api_model";
import {MatSnackBar} from "@angular/material/snack-bar";
import { NewsDetail } from '../home/home.component';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

import { OtherDocsDmis } from '../classes/otherDocsDmis';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
import { fromEvent } from 'rxjs';

export interface DocData {
  id: number;
  originalId: string;
  legal_type: string;
  name: string;
  date: string;
  down: string;
}

@Component({
  selector: "app-law",
  templateUrl: "./law.component.html",
  styleUrls: ["./law.component.css"]
})

export class LawComponent implements OnInit {
  displayedColumns: string[] = ["id", "name", "date", "doc", "down"];
  displayedColumnsMobile: string[] = ["id","name"];

  modifiedCls: string="mef2 mat-header-cell docLaw_5";
  listDocs=[];
  dataSource: MatTableDataSource<DocData>;
  key : JSGenerateRSAKey;

  isOther_All:boolean=false;
  pageIndex:number=0;
  debounce:any;
  isSearch:boolean=false;

  @Input("master") masterName: string = "";
  @Input("cateId") categoryId: string = "";
  @Input("cateName") categoryName: string = "";
  @Input("otherDocs") docsList=[];
  @Input("totalCount") itemCount:number;
  @Input("lang") lang: string = "";
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('titleSearchInput', { static: true }) titleSearchInput: ElementRef;
  @Input("oldReq") oldRequest: string;

  // add to use *ngIf in html Law.component.html
  // @ViewChild(MatSort, {static: false}) set content(sort: MatSort) {
  //   this.dataSource.sort = sort;
  // }
  constructor(
    private storage: AngularFireStorage,
    private sanitizer: DomSanitizer,
    private _swaggerApiService:swaggerApiService,
    private _snackBar:MatSnackBar,
    private http: HttpClient,
    private router: Router
  ) {
    // const docs = Array.from({ length: this.listDocs.length }, (_, k) => createDoc((k + 1),this.listDocs[k]));
    // this.dataSource = new MatTableDataSource(docs);
  }

  openReader(url: string) {
    window.open(url);
    // window.open("./assets/1.pdf");
  }

  downloadPDF(url: string) {
    let headers = new HttpHeaders();
    headers.set("Accept", "application/pdf");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Content-Type", "application/pdf");
    headers.set(
      "Access-Control-Allow-Methods",
      "GET, POST, OPTIONS, PUT, PATCH, DELETE"
    );
    headers.set(
      "Access-Control-Allow-Headers",
      "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization"
    );
    headers.set(
      "Access-Control-Allow-Headers",
      "Origin, X-Requested-With, Content-Type, Accept"
    );
    this.http
      .get(url, {
        responseType: "arraybuffer",
        headers: headers
      })
      .subscribe(response => {
        this.downLoadFile(response, "1.pdf");
      });

    // ./assets/icon-lcs.png
    //https://pdf.legalcouncilsecretariat.com/pdf/law/1.pdf
  }

  downLoadFile(data: any, type: string) {
    console.log("data : " + data);
    var blob = new Blob([data], { type: type.toString() });
    var url = window.URL.createObjectURL(blob);
    saveAs.saveAs(blob, type);
    window.open(url);
  }
  ngOnChanges(){
    this.loadingDocs(this.pageIndex);
  }
  ngOnInit() {

    if(this.categoryName=="ផ្សេងៗ" || this.categoryName=="ទាំងអស់")
    {
      this.displayedColumns= ["id","legal_type", "name", "date", "doc", "down"];
      this.modifiedCls= "mef2 mat-header-cell docLaw_6";
      this.isOther_All=true;
    }

    fromEvent(this.titleSearchInput.nativeElement, 'keyup').pipe(

      // get value
      map((event: any) => {
        return event.target.value;
      })
      // if character length greater then 2
      , filter(res => res=='' || res.length>2)

      // Time in milliseconds between key events
      , debounceTime(1000)

      // If previous query is diffent from current   
      , distinctUntilChanged()

      // subscription for response
    ).subscribe((text: string) => {
      // console.info('text = ',text);
      //To verify user is searching
        this.isSearch=true;
        let publishDate=new Date().toISOString();
        this.loadDocs(text,this.categoryId,publishDate,0,20,1);
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadingDocs(n_o:number){
      if(this.docsList.length>0)
      {
        const docs = Array.from({ length: this.docsList.length }, (_, k) => createDoc((k + 1),this.lang,this.docsList[k],n_o));
        this.dataSource = new MatTableDataSource(docs);
      }
      else
      {
        const docs = Array.from({ length: 0 }, (_, k) => createDoc((k + 1),this.lang,this.docsList[k],n_o));
        this.dataSource = new MatTableDataSource(docs);
      }
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = ""; //""; //this.masterName;
    this.paginator.hidePageSize=true;
    if(this.isSearch)
    {
        this.paginator.pageIndex = 0;
        this.pageIndex=0;//To value of pageIndex that put is as condition next/previous
        this.isSearch=false;//To notify user is not searching
    }

  }
  nav = (window.navigator as any);
  downloadFilePDF(url) {

    this._swaggerApiService.getPDF(url)
      .subscribe(x => {

          // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
          var newBlob = new Blob([x], { type: "application/pdf" });

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && this.nav.msSaveOrOpenBlob) {
              this.nav.msSaveOrOpenBlob(newBlob);
              return;
          }

          // For other browsers: 
          // Create a link pointing to the ObjectURL containing the blob.
          // const data = window.URL.createObjectURL(newBlob); //previous version
          const data = URL.createObjectURL(newBlob);

          var link = document.createElement('a');
          link.href = data;
          //To get pdf file name from link
          var fileName = url.split("/");
          fileName = fileName[fileName.length-1];
          link.download = fileName;//we want to set name of our download file
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

          setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
              link.remove();
          }, 100);
      });
  }
  onPaginateChange(event){
    let searchTitle=this.titleSearchInput.nativeElement.value;
    searchTitle=searchTitle.trim();
    // console.info("pageSize = ",event.pageSize+", pageIndex = "+event.pageIndex);
    // console.info("getNumberOfPages = ",this.paginator.getNumberOfPages());
    if(event.pageIndex === this.pageIndex+1)
    {
      // console.info("next");
      let publishDate = this.docsList[this.docsList.length-1].publishAt;

      this.loadDocs(searchTitle,this.categoryId,publishDate,event.pageIndex,20,1);
    }
    else if(event.pageIndex === this.pageIndex-1)
    {
      // console.info("previous");
      let publishDate = this.docsList[0].publishAt

      this.loadDocs(searchTitle,this.categoryId,publishDate,event.pageIndex,20,0)
    }
    this.pageIndex = event.pageIndex;
  }
  loadDocs(searchTitle,category_id,publishAt,numOrder,numLimit,isNext)
  {
    this.docsList=[];
    this.listDocs=[];
    this.key=new JSGenerateRSAKey();

    let objDocs=new ApiModel();
    objDocs.public_key=this.key.getPublicKey();
    objDocs.modified_date=publishAt;
    objDocs.category=category_id;
    objDocs.limit=numLimit;
    objDocs.keyword=searchTitle;
    objDocs.sortBy='';
    objDocs.isNext=isNext;
    objDocs.oldReq = this.oldRequest;

    this._swaggerApiService.postDMISDocuments(objDocs)
      .subscribe
      (
        data=>
        {
          let objDecrypt=new ApiModel();
          objDecrypt.private_key=this.key.getPrivateKey();
          objDecrypt.encryptedData=data;
          this._swaggerApiService.postDecrypted(objDecrypt)
            .subscribe
            (
              data=>
              {
                // console.info("docs123 = ",data);
                if(data.statusCode==200)
                {
                  if(data.item.total>0 && data.item!="Unauthorized")
                  {

                      data.item.data.item.forEach(item =>{
                          this.listDocs.push(item);
                        });
                        this.itemCount= data.item.total;
                        this.oldRequest=data.oldReq;
                      this.docsList=this.listDocs;
                  }
                  else if(data.statusCode==200 && data.item.total==0)
                  {
                      console.info("oops! no more records found.");
                      this.itemCount= 0;
                  }
                  else
                  {
                    //Toast message to users
                    this._snackBar.open("OtherDocs : "+data.message, "OK", {
                      duration: 2000
                    });
                  }
                }
                this.loadingDocs(numOrder);
                
              },(error:HttpResponse<Response>) => {
                let errorMsg=error['message'];
                // this._snackBar.open("OtherDocs Decrypt Res_Err: "+errorMsg, "OK", {
                //   duration: 2000
                // });
                console.info("OtherDocs Decrypt Res_Err: "+errorMsg);
              }
            )
        },(error:HttpResponse<Response>) => {
          let errorMsg=error['message'];
          this._snackBar.open("OtherDocs Res_Err: "+errorMsg, "OK", {
            duration: 2000
          });
        }
      )
  }
}
function groupBy(data, property) {
  return data.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}
function createDoc(id: number,currentLanguage,objDoc,n_o): DocData {
  let nameDocs='';
  let legalType='';
  //docs title
  if(currentLanguage=='fr' && (objDoc.frTitle!=null && objDoc.frTitle!=undefined && objDoc.frTitle!='' && objDoc.frTitle!="null"))
  {
    nameDocs=objDoc.frTitle;
  }
  else if(currentLanguage=='en' && (objDoc.enTitle!=null && objDoc.enTitle!=undefined && objDoc.enTitle!='' && objDoc.enTitle!="null"))
  {
    nameDocs=objDoc.enTitle;
  }
  else
  {
    nameDocs=objDoc.khTitle;
  }
  // legal type
  if(currentLanguage=='fr' && (objDoc.tolId[0].nameFr!=null && objDoc.tolId[0].nameFr!=undefined && objDoc.tolId[0].nameFr!='' && objDoc.tolId[0].nameFr!="null"))
  {
    legalType=objDoc.tolId[0].nameFr;
  }
  else if(currentLanguage=='en' && (objDoc.tolId[0].nameEn!=null && objDoc.tolId[0].nameEn!=undefined && objDoc.tolId[0].nameEn!='' && objDoc.tolId[0].nameEn!="null"))
  {
    legalType=objDoc.tolId[0].nameEn;
  }
  else
  {
    legalType=objDoc.tolId[0].nameKh;
  }
  return {
    id: (n_o*20)+id,
    originalId:objDoc._id,
    legal_type: legalType,
    name: nameDocs,
    date: objDoc.publishAt,
    down:
    "https://dmis.legalcouncilmef.com/api/law/" + objDoc.docPath
      // "https://firebasestorage.googleapis.com/v0/b/legal-council-secretary.appspot.com/o/law%2F1.pdf?alt=media"
  };
}
