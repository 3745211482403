import { Component,AfterViewInit,OnDestroy } from "@angular/core";
import { MatCarousel, MatCarouselComponent } from "@ngmodule/material-carousel";
import {
  MatCarouselSlide,
  MatCarouselSlideComponent
} from "@ngmodule/material-carousel";
import { MatTabsModule } from "@angular/material/tabs";
import { PageEvent } from "@angular/material/paginator";
import { Router,NavigationEnd } from "@angular/router";
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {SharedDataService} from "./services/sharedDataService";
import { messaging } from 'firebase';
import { Route } from '@angular/compiler/src/core';
declare var gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements AfterViewInit, OnDestroy{
  private routerSubscription: Subscription;
  images = [
    "https://firebasestorage.googleapis.com/v0/b/legal-council-secretary.appspot.com/o/carousel%2F2.jpg?alt=media",
    "https://firebasestorage.googleapis.com/v0/b/legal-council-secretary.appspot.com/o/carousel%2F1.jpg?alt=media",
    "https://firebasestorage.googleapis.com/v0/b/legal-council-secretary.appspot.com/o/carousel%2F0.jpg?alt=media"
  ];
  title = "lc-web-angular";
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  // MatPaginator Output
  pageEvent: PageEvent;

  page_not_found=false;
  constructor(private _sharedDataService:SharedDataService,private router:Router){
  }

  ngAfterViewInit(): void {
    // subscribe to router events and send page views to Google Analytics
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'UA-176705038-1', {'page_path': event.urlAfterRedirects}); //legalcouncilmef.com
        // gtag('config', 'UA-179233703-1', {'page_path': event.urlAfterRedirects}); //local-lcs-website
        // gtag('config', 'UA-179233703-3', {'page_path': event.urlAfterRedirects}); //lcswebsite.local.com
      });
  }
  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
  }
  ngOnInit()
  {
    this._sharedDataService.currentMessage
    .subscribe
    (
      message=>
      {
        this.page_not_found=message;
      }
    )
  }
}
